import React, { Fragment, useState, useContext } from "react"
import i18n from "i18next"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import logo1 from "../images/home/PortafolioLogo.png"
import logo2 from "../images/home/whyUsTiempo.png"
import logo3 from "../images/home/whyUsVanguardia.png"
import OurExpertiseHome from "./home/OurExpertiseHome"
import { LoadingContext } from "./context/LoadingContext"
import videoUrl from "../images/home/VideoExsis2023.mp4"
import ReactPlayer from "react-player/lazy"
import { Link } from "gatsby"

const SharedBanner = ({
  bannerImageURL,
  scrollForm,
}) => {
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <div className="carousel">
        <div className="shared-banner-container">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalHome">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="flex-1 self-center m-4 ml-0 desktop:mt-4 tablet:ml-4 mobile:mt-16"
              >
                <div className="text-left font-avenir-black text-44 tablet:pl-24 text-gray-700 mb-32 tablet:mb-0 leading-tight">
                  {i18n.language === "en" ? (
                    <div className="mb-10">
                      <p>
                        E<span className="text-blue-100">x</span>pedite your ne
                        <span className="text-blue-100">x</span>t
                      </p>
                      <p>Idea</p>
                      <p>Project</p>
                      <p>Concept</p>
                    </div>
                  ) : (
                    <div className="mb-10">
                      <p>
                        E<span className="text-blue-100">x</span>plora tu
                        siguiente
                      </p>
                      <p>Idea</p>
                      <p>Proyecto</p>
                      <p>Concepto</p>
                    </div>
                  )}

                  <Link to="/contact-us">
                    <button className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-12 py-4">
                      {i18n.t("pageServiceHunting.whyUsHunting.button")}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0 cursor-pointer">
                <div
                  className="bannerAcademyContentStyle"
                  onClick={handleClickOpen}
                >
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipalMobileHome">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className=" m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="text-left font-avenir-black text-44 tablet:pl-24 text-gray-700 mb-32 tablet:mb-0 leading-tight">
                  {i18n.language === "en" ? (
                    <div className="mb-10">
                      <p>
                        E<span className="text-blue-100">x</span>pedite your ne
                        <span className="text-blue-100">x</span>t
                      </p>
                      <p>Idea</p>
                      <p>Project</p>
                      <p>Concept</p>
                    </div>
                  ) : (
                    <div className="mb-10">
                      <p>
                        E<span className="text-blue-100">x</span>plora tu
                        siguiente
                      </p>
                      <p>Idea</p>
                      <p>Proyecto</p>
                      <p>Concepto</p>
                    </div>
                  )}

                  <Link to="/contact-us">
                    <button className="bg-blue-100 w-full desktop:w-fit text-white text-16 font-sofiaPro-bold rounded-lg px-12 py-4">
                      {i18n.t("pageServiceHunting.whyUsHunting.button")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div className="desktop:flex desktop:flex-wrap desktop:px-6">
            <div className="mt-8 desktop:ml-20">
              <p className="textWhyUsHome ml-4 text-left leading-tight-desktop font-responsive-cards--desktop mt-4 text-16">
                {i18n.t("home.whyus")}
              </p>

              <p
                className={
                  windowWidth >= 1025 ? "text_Why_us ml-4" : "text_Why_usMobile"
                }
              >
                {i18n.t("home.whyUs.text")}
              </p>

              <div className="desktop:flex desktop:ml-4 mt-12">
                <a
                  href="https://www.portafolio.co/negocios/nueve-empresas-tic-del-pais-buscaran-clientes-en-china-535543"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logo1}
                    alt="...."
                    className={
                      windowWidth >= 1025 ? "mr-12" : "mr-auto ml-auto mt-4"
                    }
                  />
                </a>
                <a
                  href="https://www.eltiempo.com/bogota/empresario-miguel-jimenez-de-la-empresa-exsis-que-tiene-otras-empresas-307614"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logo2}
                    alt="...."
                    className={
                      windowWidth >= 1025 ? "mr-12" : "mr-auto ml-auto mt-12"
                    }
                  />
                </a>
                <a
                  href="https://www.vanguardia.com/economia/negocios/perfiles-tecnologicos-los-mas-demandados-en-el-mercado-laboral-AD2775460"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logo3}
                    alt="...."
                    className={
                      windowWidth >= 1025 ? "mr-12" : "mr-auto ml-auto mt-12"
                    }
                  />
                </a>
              </div>
            </div>
          </div>
          <OurExpertiseHome scrollForm={scrollForm} />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={false}
        >
          <DialogContent>
            <div>
              <ReactPlayer
                width="66rem"
                height="100%"
                className="VideoDiv"
                playing={true}
                controls={true}
                url={videoUrl}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </Fragment>
  )
}

SharedBanner.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default SharedBanner
