import React, { useState } from "react"
import i18n from "i18next"
import SEO from "./../components/seo"
import Layout from "../components/layout"
import { withNamespaces } from "react-i18next"
import BannerSEO from "./../images/home/Home1IN.png"
import iconPurple from "./../images/home/iconPurple.png"
import iconGreen from "./../images/home/iconGreen.png"
import iconBlue from "./../images/home/iconBlue.png"
import CoreSolution from "../components/home/CoreSolution"
import Customers from "./../components/ourDNA/Customers"
import BannerHomeOp from "../components/home/BannerHomeOp"
import PrivacyPolicy from "./../components/shared/privacyPolicy/PrivacyPolicy"
import JoinOurTeamHome from "../components/home/JoinOurTeamHome"
import History from "../components/home/History"
import Article from "../components/home/Articles"
import Experts from "../components/home/Experts"
import Counts from "../components/home/Counts"
import PopUp from "../components/popUpCumpleanos/PopUp"

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openPrivacyPolicy: false,
      isXOpen: true,
    }

    this.redirectTalent = this.redirectTalent.bind(this)
    this.redirectInnovation = this.redirectInnovation.bind(this)
    this.redirectDataAnalytics = this.redirectDataAnalytics.bind(this)
    this.redirectDev = this.redirectDev.bind(this)
    this.redirectAgile = this.redirectAgile.bind(this)
    this.redirectJoin = this.redirectJoin.bind(this)
    this.redirectPost = this.redirectPost.bind(this)
    this.setPrivacy = this.setPrivacy.bind(this)
    this.myRef = React.createRef()
    this.executeScroll = this.executeScroll.bind(this)
  }

  handleXClick = () => {
    this.setState({ isXOpen: false })
  }

  executeScroll() {
    this.myRef.current.scrollIntoView()
  }

  setPrivacy() {
    this.setState({ openPrivacyPolicy: false })
  }

  onEnterOurServicesViewPort() {
    document
      .getElementById("ourServices")
      .classList.add("main-title--animation")
  }

  onExitOurServicesViewPort() {
    document
      .getElementById("ourServices")
      .classList.remove("main-title--animation")
  }

  onEnterOurServicesBackgroundViewPort() {
    document
      .getElementById("ourServicesBackground")
      .classList.add("background-our-services--animation")
  }

  onEnterJoinOurTeamViewPort() {
    document
      .getElementById("joinOurTeam")
      .classList.add("main-title--animation")
  }

  onExitJoinOurTeamViewPort() {
    document
      .getElementById("joinOurTeam")
      .classList.remove("main-title--animation")
  }

  onEnterTransformViewPort() {
    document.getElementById("transform").classList.add("main-title--animation")
  }

  onExitTransformViewPort() {
    document
      .getElementById("transform")
      .classList.remove("main-title--animation")
  }

  redirectTalent() {
    this.props.history.push("/service-talent")
  }

  redirectInnovation() {
    this.props.history.push("/service-innovation")
  }

  redirectDataAnalytics() {
    this.props.history.push("/service-data-analytics")
  }

  redirectDev() {
    this.props.history.push("/service-dev-cloud")
  }

  redirectAgile() {
    this.props.history.push("/transformation")
  }

  redirectJoin() {
    this.props.history.push("/join-our-team")
  }

  redirectPost() {
    this.props.history.push("/blog")
  }

  render() {
    const whyusarr = [
      {
        image: iconGreen,
        texto: "Fábrica de software",
        textEnglish: "Software Factory",
      },
      {
        image: iconPurple,
        texto: "Equipos ágiles",
        textEnglish: "Agile Teams",
      },
      {
        image: iconBlue,
        texto: "Aumento del personal",
        textEnglish: "Staff Augmentation",
      },
    ]

    return (
      <Layout>
        {/*this.state.isXOpen && <PopUp handleClose={this.handleXClick}></PopUp>*/}
        {this.state.openPrivacyPolicy ? (
          <PrivacyPolicy setOpenPrivacyPolicy={this.setPrivacy} />
        ) : null}
        <SEO
          title={i18n.t("titles.titleseo")}
          description={i18n.t("titles.descriptionseo")}
          image={BannerSEO}
        />
        <BannerHomeOp scrollForm={this.executeScroll} />

        {whyusarr.length ? (
          <CoreSolution
            id="our services"
            title="Core Solutions"
            arrayitems={whyusarr}
          />
        ) : null}

        <Customers />
        <JoinOurTeamHome />

        <History />

        <Article />

        <Experts />
        <div ref={this.myRef}>
          <Counts />
        </div>
      </Layout>
    )
  }
}

export default withNamespaces()(Home)
